import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
    createActivity,
    selectIsLoading,
} from '../../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation from '../../../../common/utils/formValidation';
import {
    ActivityData,
    CloneActivityData,
    CreateActivityData,
    DeliverySession,
} from '../../../interfaces/activity';
import { FormSectionMode } from '../../../../common/constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivitySessionControlArrayFormValues,
    createActivityValidationConfig,
    SessionAttributeEditorItem,
    sessionAttributeValidationConfig,
    getSessionAttributeEditorItem,
    ActivityFormSectionProps,
} from '../Common/Common';
import { MeridiemFieldValue } from '../../../interfaces/meridiem';
import { selectUser } from '../../../../common/store/slices/userSlice';
import ActivityFormSection from '../FormSections/ActivityFormSection';

const dayInMilliseconds = 86400000;

export const getUTCMilliseconds = (
    dateString: string,
    timeString: string,
    deliveryTimezone: string,
) => {
    // if emtpy, return invalid number, so session date is set empty
    if (dateString === '') return NaN;
    // get the utc time for the delivery location's time
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const utcTime = dayjs
        .tz(dateString + ' ' + timeString, deliveryTimezone)
        .utc();
    return utcTime.toDate().getTime();
};

export const convertTo24HourFormat = (
    time: string,
    meridiem: MeridiemFieldValue,
) => {
    if (!time) {
        return '';
    }

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (meridiem === MeridiemFieldValue.Pm) {
        hours = `${parseInt(hours, 10) + 12}`;
    }

    return `${hours}:${minutes}`;
};

export const getDeliverySession = (
    sessionItem: SessionAttributeEditorItem,
    timeZone: string,
): DeliverySession => {
    const militaryStartTime = convertTo24HourFormat(
        sessionItem.startTime,
        sessionItem.startTimeMeridiemFieldOption,
    );
    const militaryEndTime = convertTo24HourFormat(
        sessionItem.endTime,
        sessionItem.endTimeMeridiemFieldOption,
    );

    const millisecondStartTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryStartTime,
        timeZone,
    );
    let millisecondEndTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryEndTime,
        timeZone,
    );

    // if the end time is earlier than the start time
    // it means we're out of the bounds of the previous 24 hours
    // we need to offset by a day
    if (millisecondStartTime > millisecondEndTime) {
        millisecondEndTime = millisecondEndTime + dayInMilliseconds;
    }

    return {
        start_timestamp: millisecondStartTime / 1000,
        end_timestamp: millisecondEndTime / 1000,
    };
};

const ActivityCloneForm = ({
    createActivityFormState,
}: {
    createActivityFormState: ActivityData;
}) => {
    const isLoading = useSelector(selectIsLoading);
    const [customers, setCustomers] = useState<Array<number>>([]);
    const [invoices, setInvoices] = useState<Array<number>>([]);
    const [autoAssignInstructor, setAutoAssignInstructor] = useState<
        boolean | undefined
    >(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const { addNotification } = useNotifications();

    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Omit<CloneActivityData, 'delivery_sessions'>,
        ActivitySessionControlArrayFormValues
    >();

    const [formValues, setFormValues] = useState(createActivityFormState);

    const [partnerInitiative, setPartnerInitiative] = useState<string>('');

    const [courseDays, setCourseDays] = useState<number>();

    const handleCourseDaysChange = (courseDays: number) => {
        setCourseDays(courseDays);
    };

    const [sessionAttributeEditorItems, setSessionAttributeEditorItems] =
        useState(
            formValues.delivery_sessions.map((item) =>
                getSessionAttributeEditorItem(
                    item,
                    formValues.delivery_timezone,
                ),
            ) || [],
        );

    useEffect(() => {
        if (createActivityFormState && user) {
            setFormValues((values) => ({
                ...values,
                ...{
                    delivery_sessions: createActivityFormState
                        ? createActivityFormState.delivery_sessions
                        : [],
                    operations_owner:
                        user?.profile.full_name ??
                        createActivityFormState.operations_owner,
                    operations_owner_email:
                        user?.profile.email ??
                        createActivityFormState.operations_owner_email,
                    scheduler:
                        user?.profile.full_name ??
                        createActivityFormState.scheduler,
                    scheduler_email:
                        user?.profile.email ??
                        createActivityFormState.scheduler_email,
                    activity_status: 'Hold',
                },
            }));
        }
    }, [createActivityFormState, user]);

    const handleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const validateAndHandleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const handleSessionItemEvent = (
        sessionItems: Array<SessionAttributeEditorItem>,
    ) => {
        setSessionAttributeEditorItems(sessionItems);
    };

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleCreateActivity = async () => {
        const invalid = validateForm(
            formValues,
            createActivityValidationConfig,
        );
        const arrayInvalid = validateFormControlArray(
            { sessionAttributeEditorItems },
            sessionAttributeValidationConfig,
        );
        const isActivityFormInvalid = invalid || arrayInvalid;
        formValues.course_days = courseDays ? courseDays : 1;
        if (!isActivityFormInvalid) {
            const activityData: CloneActivityData = {
                activity_name: formValues.activity_name,
                activity_status: formValues.activity_status,
                activity_type: formValues.activity_type,
                program: formValues.program,
                activity_audience: formValues.activity_audience,
                partner_initiative: formValues.partner_initiative,
                activity_modality: formValues.activity_modality,
                course_name: formValues.course_name,
                topic_name: formValues.topic_name,
                provider: formValues.provider,
                delivery_timezone: formValues.delivery_timezone,
                delivery_city: formValues.delivery_city,
                delivery_state: formValues.delivery_state,
                delivery_country: formValues.delivery_country,
                delivery_region: formValues.delivery_region,
                delivery_geo: formValues.delivery_geo,
                delivery_language: formValues.delivery_language,
                course_days: formValues.course_days,
                operations_owner: formValues.operations_owner,
                operations_owner_email: formValues.operations_owner_email,
                scheduler: formValues.scheduler,
                scheduler_email: formValues.scheduler_email,
                customer_support_manager: formValues.customer_support_manager,
                customer_support_manager_email:
                    formValues.customer_support_manager_email,
                delivery_sessions: sessionAttributeEditorItems
                    .filter(
                        (item) =>
                            item.startTime && item.endTime && item.dateString,
                    )
                    .map((item) =>
                        getDeliverySession(item, formValues.delivery_timezone),
                    ),
                billing_invoices: (formValues.billing_invoices ?? [])
                    .filter((invoice, index) => {
                        return invoices.includes(index);
                    })
                    .map((invoice) => {
                        return {
                            ...invoice,
                            number_of_participants: 0,
                            billing_status: 'Draft',
                            billed_amount: 0,
                            mast_invoice_number: null,
                            ofa_invoice_number: null,
                            ofa_invoice_url: null,
                            s3_path: null,
                            batch_id: null,
                            invoice_reference: null,
                            aws_sor: null,
                            sfdc_opportunity_id: null,
                            line_items: [],
                        };
                    }),
                customers: (formValues.customers ?? [])
                    .filter((customer, index) => {
                        return customers.includes(index);
                    })
                    .map((customer) => {
                        return {
                            ...customer,
                            number_of_students_attended: undefined,
                            number_of_students_committed: undefined,
                            sfdc_opportunity_id: undefined,
                            sfdc_scheduling_request_id: undefined,
                            sfdc_contract_request_id: undefined,
                            sfdc_line_item_id: undefined,
                            sfdc_opportunity_name: undefined,
                            email_sent: undefined,
                            notify_customer_date: undefined,
                            most_recent_email_date: undefined,
                            accounts_payable_email: undefined,
                            pricing_model: undefined,
                            training_briefing_what_the_customer_does: undefined,
                            training_briefing_where_are_they_in_their_cloud_journey: undefined,
                            training_briefing_motivations_for_using_aws: undefined,
                            training_briefing_special_needs_for_training_delivery: undefined,
                            revenues: [],
                        };
                    }),
                auto_assign_instructor: autoAssignInstructor,
            };
            const { isSuccessful, createdActivityId } = await dispatch<any>(
                createActivity(activityData),
            );

            addNotification({
                id: `create-activity-${Date.now()}`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content: 'Activity cloned.',
                      }
                    : {
                          type: 'error',
                          content:
                              'An error occurred while cloning the activity.',
                      }),
            });

            if (isSuccessful) {
                history.push({
                    pathname: `/activities/${createdActivityId}`,
                });
            }
        } else {
            handleScrollToError();
        }
    };

    const handleCustomersChange = (customerIndexes: Array<number>) => {
        setCustomers(customerIndexes);
    };

    const handleInvoicesChange = (invoiceIndexes: Array<number>) => {
        setInvoices(invoiceIndexes);
    };

    const handleAutoAssignInstructorChange = (
        autoAssignInstructor: boolean | undefined,
    ) => {
        setAutoAssignInstructor(autoAssignInstructor);
    };

    const handlePartnerInitiativeChange = (partnerInitiative: string) => {
        setPartnerInitiative(partnerInitiative);
    };

    const createActivityProps: ActivityFormSectionProps<
        Omit<ActivityData, 'delivery_sessions'>
    > & {
        handleCourseDaysChange: (courseDays: number) => void;
        handleAutoAssignInstructorChange: (
            autoAssignInstructor: boolean | undefined,
        ) => void;
        handlePartnerInitiativeChange: (partnerInitiative: string) => void;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        validateAndHandleFieldEvent,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        mode: FormSectionMode.Create,
        controlArrayErrors,
        handleCourseDaysChange,
        handleCustomersChange,
        handleInvoicesChange,
        handleAutoAssignInstructorChange,
        handlePartnerInitiativeChange,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, createActivityValidationConfig);
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { sessionAttributeEditorItems },
                sessionAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        validateFormControlArray,
        sessionAttributeEditorItems,
        isControlArrayInvalid,
    ]);

    return (
        <section data-testid="ActivityCreateForm">
            <Form
                header="Add activity"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-activity-cancel"
                                data-testid="ActivityCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${formValues.pk}`,
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-activity-save"
                                data-testid="ActivityCreateFormAdd"
                                loading={isLoading}
                                onClick={handleCreateActivity}
                            >
                                {`${isLoading ? 'Adding' : 'Add'} activity`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <ActivityFormSection {...createActivityProps} />
            </Form>
        </section>
    );
};

export default ActivityCloneForm;

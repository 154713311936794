import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import { EMPTY_STRING } from '../../../../common/constants/grimsby';
import {
    ActivityData,
    CreateActivityData,
    DeliveryInstructor,
    DeliverySession,
} from '../../../interfaces/activity';
import { ValidationType } from '../../../../common/utils/formValidation';
import { CourseItem } from '../../../../imt/interfaces/instructorProfile';
import { MeridiemFieldValue } from '../../../interfaces/meridiem';
import { convertTimeStampToLocalUnix } from '../../../../common/utils/date-time.utils';

export enum AudienceType {
    Public = 'Public',
    PartnerPublic = 'Partner Public',
    PartnerPublicUnlisted = 'Partner Public Unlisted',
    CommercialPrivate = 'Commercial Private',
    PartnerPrivate = 'Partner Private',
    Internal = 'Internal',
    CommercialSemiPrivate = 'Commercial Semi-Private',
    PartnerSemiPrivate = 'Partner Semi-Private',
}

export enum ActivityType {
    Bootcamp = 'Bootcamp',
    Class = 'Class',
    Event = 'Event',
    TTT = 'TTT', //Train The Trainer
    Webinar = 'Webinar',
}

export enum ActivityStatus {
    Active = 'Active',
    Canceled = 'Canceled',
    Completed = 'Completed',
    Hold = 'Hold',
    Tentative = 'Tentative',
}

export enum ActivityModality {
    ILT = 'ILT',
    Hybrid = 'Hybrid',
    vILT = 'vILT',
}

export enum ActivityProgram {
    Partner = 'Partner Program',
    Commercial = 'Commercial',
    ChannelPending = 'Channel Pending',
}

export enum ActivityLMSType {
    MyClass = 'myClass',
    Kiku = 'Kiku',
    CustomerLMS = 'CustomerLMS',
}

export enum ActivityViltType {
    Necto = 'Necto',
    Other = 'Other',
    WebEx = 'WebEx',
}

export interface SessionAttributeEditorItem {
    deliverySessionId?: string;
    dateString: string;
    startTime: string;
    endTime: string;
    endTimeMeridiemFieldOption: MeridiemFieldValue;
    startTimeMeridiemFieldOption: MeridiemFieldValue;
}

export interface ActivitySessionControlArrayFormValues {
    sessionAttributeEditorItems: SessionAttributeEditorItem;
}

export interface ActivityInstructorControlArrayFormValues {
    instructorAttributeEditorItems: DeliveryInstructor;
}

export type ActivityControlArrayValues = ActivitySessionControlArrayFormValues &
    ActivityInstructorControlArrayFormValues;

export type ActivityControlArrayErrors<ControlArrayFormValues> = {
    [controlArrayFormValuesKey in keyof ControlArrayFormValues]: {
        [key: number]: {
            [key in keyof ControlArrayFormValues[controlArrayFormValuesKey]]?: string;
        };
    };
};

interface ShowAdditionalFieldProps {
    showDeliveryAddress: boolean;
    showNumberOfStudentsAttended: boolean;
    showPartnerInitiative: boolean;
    showLMSType: boolean;
    showClassSize: boolean;
    showVILTType: boolean;
}

export type ActivityFormSectionProps<T> = FormSectionProps<T> & {
    setShowAdditionalFields?: React.Dispatch<
        React.SetStateAction<ShowAdditionalFieldProps>
    >;
    showAdditionalFields?: ShowAdditionalFieldProps;
    handleSessionItemEvent: (
        sessionItems: Array<SessionAttributeEditorItem>,
    ) => void;
    sessionAttributeEditorItems: Array<SessionAttributeEditorItem>;
    controlArrayErrors: ActivityControlArrayErrors<ActivitySessionControlArrayFormValues>;
    handleCustomersChange?: (customerIndexes: Array<number>) => void;
    handleInvoicesChange?: (invoiceIndexes: Array<number>) => void;
    handleSubmitEvent?: () => Promise<void>;
};

export interface InstructorAvailabilityData {
    booked_activities: Array<ActivityData>;
    back_to_back_activities: Array<ActivityData>;
    pk: string;
    warning_messages: Array<string>;
    error_messages: Array<string>;
    availability: string;
    full_name: string;
    delivery_languages: Array<string>;
    delivery_countries: Array<string>;
    courses: Array<CourseItem> | null;
}

export interface InstructorAssignmentConflict {
    instructor: InstructorAvailabilityData | null;
    activity: ActivityData | null;
}

export type ActivityDeliveryDetailsFormSectionProps = ActivityFormSectionProps<
    Omit<ActivityData, 'delivery_sessions'>
> & {
    instructorAttributeEditorItems: Array<DeliveryInstructor>;
    handleInstructorItemEvent: (
        instructorItems: Array<DeliveryInstructor>,
    ) => void;
    controlArrayErrors?: ActivityControlArrayErrors<ActivitySessionControlArrayFormValues> &
        ActivityControlArrayErrors<ActivityInstructorControlArrayFormValues>;
    instructorValidationResponse: InstructorAvailabilityData[];
    handleAddInstructor: (instructorPk: string) => void;
    instructorItemErrors?: any;
    areSessionsInvalid?: any;
};

export type ActivitySFDCDeliveryDetailsFormSectionProps = Omit<
    ActivityFormSectionProps<Omit<ActivityData, 'delivery_sessions'>> & {
        instructorAttributeEditorItems: Array<DeliveryInstructor>;
        handleInstructorItemEvent: (
            instructorItems: Array<DeliveryInstructor>,
        ) => void;
        controlArrayErrors?: ActivityControlArrayErrors<ActivitySessionControlArrayFormValues> &
            ActivityControlArrayErrors<ActivityInstructorControlArrayFormValues>;
        instructorValidationResponse: InstructorAvailabilityData[];
        handleAddInstructor: (instructorPk: string) => void;
        instructorItemErrors?: any;
    },
    'validateAndHandleFieldEvent'
>;

export const currencyFormatter = (amount: any, currency: string) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    return formatter.format(amount);
};

export type BasicActivityFormSectionProps = Omit<
    ActivityFormSectionProps<Omit<ActivityData, 'delivery_sessions'>>,
    | 'sessionAttributeEditorItems'
    | 'handleSessionItemEvent'
    | 'controlArrayErrors'
    | 'mode'
>;

export const createActivityValidationConfig: {
    [key in ValidationType]?: Array<
        keyof Omit<CreateActivityData, 'delivery_sessions'>
    >;
} = {
    required: [
        'activity_status',
        'activity_type',
        'program',
        'activity_audience',
        'activity_modality',
        'course_name',
        'operations_owner',
        'provider',
        'delivery_timezone',
        'delivery_city',
        'delivery_country',
        'delivery_region',
        'delivery_geo',
        'delivery_language',
    ],
};

export const sessionAttributeValidationConfig: {
    [activityControlArrayFormValuesKey in keyof ActivitySessionControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof ActivitySessionControlArrayFormValues[activityControlArrayFormValuesKey]
        >;
    };
} = {
    sessionAttributeEditorItems: {
        required: [
            'dateString',
            'startTime',
            'endTime',
            'endTimeMeridiemFieldOption',
            'startTimeMeridiemFieldOption',
        ],
    },
};

export const getSessionAttributeEditorItem = (
    {
        id,
        start_timestamp: startTimeStamp,
        end_timestamp: endTimeStamp,
    }: DeliverySession,
    deliveryTimeZone: string,
): SessionAttributeEditorItem => {
    const deliverySessionId = id;
    const { time: startTime, meridiem: startTimeMeridiemFieldOption } =
        getHourTimeFormat(startTimeStamp, deliveryTimeZone);
    const { time: endTime, meridiem: endTimeMeridiemFieldOption } =
        getHourTimeFormat(endTimeStamp, deliveryTimeZone);
    const startDate = startTimeStamp
        ? new Date(startTimeStamp * 1000)
        : new Date();
    const localeSessionDate = startDate.toLocaleDateString('en-US', {
        timeZone: deliveryTimeZone,
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    const dateParts = localeSessionDate.split('/');
    return {
        deliverySessionId: deliverySessionId ?? '',
        startTime: startTime ?? '',
        endTime: endTime ?? '',
        dateString: `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`, //yyyy-MM-DD
        startTimeMeridiemFieldOption: (startTimeMeridiemFieldOption ??
            '') as MeridiemFieldValue,
        endTimeMeridiemFieldOption: (endTimeMeridiemFieldOption ??
            '') as MeridiemFieldValue,
    };
};

export const getHourTimeFormat = (
    dateTime: number | null,
    timeZone: string = 'UTC',
) => {
    const localeDate = dateTime ? new Date(dateTime * 1000) : new Date();
    let localeDateTime = '';
    try {
        localeDateTime = localeDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: timeZone,
        });
    } catch (e) {
        // if timezone is invalid for some reason (very rare)
        // gracefully handling timezone
        localeDateTime = localeDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'America/Los_Angeles',
        });
    }
    const timeParts = localeDateTime.split(' ');
    return {
        time: timeParts[0],
        meridiem: timeParts[1],
    };
};

export const formatNumber = (number?: any): number => {
    if (number) {
        return !isNaN(number) ? number : 0;
    } else {
        return 0;
    }
};

export const formatGrimsbyDate = (
    timeStamp: number | null,
    timeZone = 'UTC',
) => {
    if (timeStamp == null) {
        return EMPTY_STRING;
    }
    const sessionTime = new Date(timeStamp * 1000);
    let localeSessionDate = '';
    try {
        localeSessionDate = sessionTime.toLocaleDateString('en-US', {
            timeZone: timeZone,
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        });
    } catch (e) {
        localeSessionDate = sessionTime.toLocaleDateString('en-US', {
            timeZone: 'America/Los_Angeles',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        });
    }

    return localeSessionDate;
};

export const formatDeliverySessionTime = (
    {
        start_timestamp: startTimeStamp,
        end_timestamp: endTimeStamp,
    }: DeliverySession,
    deliveryTimeZone: string,
) => {
    if (startTimeStamp == null || endTimeStamp == null) {
        return EMPTY_STRING;
    }
    const { time: startTime, meridiem: startMeridiem } = getHourTimeFormat(
        startTimeStamp,
        deliveryTimeZone,
    );
    const { time: endTime, meridiem: endMeridiem } = getHourTimeFormat(
        endTimeStamp,
        deliveryTimeZone,
    );

    return `${startTime}${startMeridiem?.toLocaleLowerCase()} - ${endTime}${endMeridiem?.toLocaleLowerCase()}`;
};

export const getActivityStartAndEndFromSessions = (
    deliverySessions: Array<DeliverySession>,
    timezone: string,
) => {
    deliverySessions.sort(
        (a, b) => (a.start_timestamp || 0) - (b.start_timestamp || 0),
    );

    const a = deliverySessions[0];
    const b = deliverySessions[deliverySessions.length - 1];
    const startTimestamp = a?.start_timestamp || 0;
    const endTimestamp = b?.end_timestamp || 0;
    return {
        start:
            convertTimeStampToLocalUnix({
                timestamp: startTimestamp,
                timezone,
            }) * 1000,
        end:
            convertTimeStampToLocalUnix({ timestamp: endTimestamp, timezone }) *
            1000,
    };
};

export const getActivityStartDateFromSessions = (
    deliverySessions: Array<DeliverySession>,
    deliveryTimezone: string,
) => {
    return getActivityStartAndEndFromSessions(
        deliverySessions,
        deliveryTimezone,
    ).start;
};

export const showEditButton = (activity: ActivityData): boolean => {
    if (activity?.activity_status === ActivityStatus.Canceled) {
        return false;
    } else return true;
};

export const isPartnerPrivateCustomer = (activityAudience: string) => {
    return (
        activityAudience === AudienceType.PartnerPrivate ||
        activityAudience === AudienceType.PartnerSemiPrivate ||
        activityAudience === AudienceType.PartnerPublicUnlisted
    );
};

export const isCommercialPrivateCustomer = (activityAudience: string) => {
    return (
        activityAudience === AudienceType.CommercialPrivate ||
        activityAudience === AudienceType.CommercialSemiPrivate
    );
};

export const isPublicCustomer = (activityAudience: string) => {
    return activityAudience === AudienceType.Public;
};

export const DATE_RANGE_FILTER_KEY = 'dateRange';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoursesResponseData } from '../../interfaces/businessDataResponse/coursesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { CourseItemData } from '../../interfaces/businessDataItem/courseItem';
import parseBoolean from '../../utils/parseBoolean';
import { AdminCreateCoursePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/coursePayload';
import { AdminUpdateCoursePayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';
import { getCatalogCoursesByMultipleGroupIds, convertToCourseItemData } from '../../api/helpers';
import { selectAllProgramTypes } from './programTypesSlice';
import { selectSelectedInstructor } from '../../../imt/store/slices/selectedInstructorSlice';
import { MAX_RETRY_TIME, RETRY_DELAY } from '../../constants/grimsby';
import { REACH_MAX_RETRY_ATTEMPTS } from '../../constants/errorMessage';
import { ApiError } from '../../classes/ApiError';

/**
 * coursesSlice manages all courses state, and contains courses actions as well as courses state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const coursesSlice = createSlice({
    name: 'courses',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<CourseItemData>,
    reducers: {
        setCoursesList: (state, action: PayloadAction<CourseItemData[]>) => {
            const byCourseName = action.payload.reduce(
                (
                    byCourseName: {
                        [key: string]: CourseItemData;
                    },
                    course: CourseItemData,
                ) => {
                    byCourseName[course.course] = {
                        ...course,
                        active: parseBoolean(course.active),
                    };
                    return byCourseName;
                },
                {},
            );
            state.entities = byCourseName;
            state.keysList = Object.keys(byCourseName);
        },
        setCourse: (state, action: PayloadAction<CourseItemData>) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and update count if adding a new program
            if (!state.entities[action.payload.course]) {
                state.keysList = [...state.keysList, action.payload.course];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.course] = action.payload;
        },
        setSelectedCourse: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        resetPartialCoursesSlice: resetBasicBusinessDataStoreState,
        resetCoursesSlice: resetExtendedBusinessDataStoreState,
        initializeCoursesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCoursesList,
    setCourse,
    setSelectedCourse,
    setError,
    setCount,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    setSearchText,
    setActive,
    resetPartialCoursesSlice,
    resetCoursesSlice,
    initializeCoursesListQueryParams,
} = coursesSlice.actions;

const handleCoursesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const { from, size, searchText: course, active } = state.courses;

        const params: BusinessDataApiQueryParams.GetCourses = {
            active,
        };

        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (course) {
            params.course = course;
        }

        try {
            const {
                result: { COURSE, total_count },
            }: HandleRequestData<CoursesResponseData> =
                await businessDataApi.getCourses(params);
            dispatch(setCoursesList(COURSE));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getCourses API error'));
        }
    };
};

// Method to fetch all courses including those from Grimsby DDB and Catalog service.
const handleAllCoursesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const programTypesList = selectAllProgramTypes(state);
        const instructor = selectSelectedInstructor(state);
        const instructorPrograms = instructor?.instructor?.programs;
        let courses: CourseItemData[];
        let courseCount: number = 0;

        // Fetch courses from Grimsby DDB.
        let retriesLeft = MAX_RETRY_TIME;
        while (retriesLeft) {
            try {
                const {
                    result: { COURSE, total_count },
                }: HandleRequestData<CoursesResponseData> =
                    await businessDataApi.getCourses();
                if (COURSE) {
                    courses = COURSE;
                    courseCount += total_count;
                    break;
                }
            } catch (error: any) {
                if (!(error instanceof ApiError && error?.statusCode === 404)) {
                    dispatch(setError(error!.toString()));
                    return;
                }
            }
            retriesLeft--;
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
        if (!retriesLeft) {
            dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
            return;
        }

        // Check if the instructor is associated with any Catalog programs.
        // If they is, fetch Catalog courses with these program ids.
        if (instructorPrograms?.length > 0) {
            retriesLeft = MAX_RETRY_TIME;
            while (retriesLeft) {
                try {
                    const catalogGroupIds = programTypesList.filter(
                        type =>
                            type.catalog_group_ids?.length > 0
                            && instructorPrograms.includes(type.program_type)
                    ).map(type => type.catalog_group_ids[0]);
                    const catalogCourses = await getCatalogCoursesByMultipleGroupIds(catalogGroupIds);
                    if (catalogCourses.length > 0) {
                        courseCount += catalogCourses.length;
                        catalogCourses.forEach(catalogCourse => courses.push(
                            convertToCourseItemData(catalogCourse)
                        ));
                    }
                    break;
                } catch (error: any) {
                    // No need to retry on 401 or 403.
                    if (error?.statusCode === 401 || error?.statusCode === 403) {
                        dispatch(setError(error?.message || 'Failed to fetch Catalog courses.'));
                        return;
                    }
                }
                retriesLeft--;
                await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            }
            if (!retriesLeft) {
                dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
                return;
            }
        }

        if (courses) {
            dispatch(setCoursesList(courses));
            dispatch(setCount(courseCount));
        }
    }
}

export const getCoursesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleCoursesListRequest());
        if (!state.courses.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const getAllCoursesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleAllCoursesListRequest());
        if (!state.courses.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 * @param originalCourse
 */
export const updateCourse = (
    data: CourseItemData,
    originalCourse: string | undefined,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        if (!originalCourse) {
            throw new Error('original Course is required');
        }

        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        const {
            course,
            topic_name_required,
            active,
            pk: uuid,
            associated_programs,
            associated_certifications,
            course_days,
            short_name,
            sku_id,
            course_category,
            course_domain,
            course_skill_level,
        } = data;

        try {
            await businessDataApi.updateCourse({
                course,
                topic_name_required,
                active,
                uuid,
                associated_programs,
                associated_certifications,
                course_days: `${course_days}`,
                short_name,
                sku_id,
                course_category,
                course_domain,
                course_skill_level,
            } as AdminUpdateCoursePayloadData);
            await dispatch(handleCoursesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            const originalIndex =
                state.courses.keysList.indexOf(originalCourse);

            const courseList = selectAllCourses(state);

            courseList.splice(originalIndex, 1, data);

            dispatch(setCoursesList(courseList));
            dispatch(
                setCourse({
                    ...state.courses.entities[data.course],
                    active,
                    associated_programs,
                    associated_certifications,
                    topic_name_required,
                    course_days,
                    short_name,
                    sku_id,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addCourse = (newCourseData: AdminCreateCoursePayloadData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addCourse(newCourseData);
            // now refresh the keysList
            await dispatch(handleCoursesListRequest());

            // shove the item into the keysList, but only if it's not there already
            if (!state.courses?.entities[newCourseData.course]) {
                const newProgramData: CourseItemData = {
                    ...newCourseData,
                };
                dispatch(setCourse(newProgramData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllCourses = (state: GlobalState) => {
    return state.courses.keysList.map(
        (courseName) => state.courses.entities[courseName],
    );
};

export const selectAllActiveCourses = (
    state: GlobalState,
): Array<CourseItemData> => {
    return getActiveBusinessDataValues(
        state.courses.keysList,
        state.courses.entities,
    );
};

export const selectIsLoading = (state: GlobalState) => state.courses.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.courses.isLoaded;

export const selectSelectedCourse = (state: GlobalState) => {
    return state?.courses?.selectedItemKey
        ? state?.courses?.entities[state?.courses?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.courses.error;

export const selectCount = (state: GlobalState) => state.courses.count;

export const selectSearchText = (state: GlobalState) =>
    state.courses.searchText;

export default coursesSlice.reducer;

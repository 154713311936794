import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    AttributeEditor,
    ColumnLayout,
    CustomDetailEvent,
    FormField,
    FormSection,
    Input,
    Select,
} from '@amzn/awsui-components-react';

import {
    getUserList,
    resetUserListSlice,
    selectError as selectUserListError,
    selectIsLoaded as selectIsUserListLoaded,
    selectIsLoading as selectIsUserListLoading,
    selectSearchText as selectUserListSearchText,
    selectUserList,
    setSearchText as setUserListSearchText,
    setViasRoles,
} from '../../../../common/store/slices/userListSlice';
import {
    getCostTypesList,
    selectAllActiveCostTypes,
    selectIsLoaded as selectIsCostTypesListLoaded,
    selectIsLoading as selectIsCostTypesListLoading,
} from '../../../../common/store/slices/costTypesSlice';
import { selectUser } from '../../../../common/store/slices/userSlice';
import handlePreSelectedValue from '../../../../common/utils/handlePreSelectedValue';
import {
    GANDALF_SCHEDULE_ADMIN_GROUP,
    GANDALF_SCHEDULE_READ_ONLY,
    GANDALF_SYS_ADMIN_GROUP,
} from '../../../../common/constants/grimsby';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import { UserProfileData } from '../../../../common/interfaces/userProfile';
import { ActivityData, PaymentStatus } from '../../../interfaces/activity';
import {
    CostItemAttributeEditorItem,
    EditOpsAndRegFormSectionProps,
} from '../Edit/EditOperationsAndRegistrationDetailsForm';
import { CostTypeItemData } from '../../../../common/interfaces/businessDataItem/costTypeItem';
import {
    currencyCodesOptions,
    currencyCodesOptionsLookup,
} from '../../../constants/currencyCodes';
import {
    ActivityLMSType,
    ActivityModality,
    AudienceType,
} from '../Common/Common';
// to get country list.
import {
    getLocationsList,
    selectIsLoaded as selectIsLocationListLoaded,
    selectIsLoading as selectIsLocationListLoading,
} from '../../../../common/store/slices/locationsSlice';
import {
    getCountriesList,
    selectAllCountries,
    selectIsLoaded as selectIsCountiesLoaded,
    selectIsLoading as selectIsCountriesLoading,
} from '../../../../common/store/slices/countriesSlice';
import { Button } from '@amzn/awsui-components-react-v3';
import {
    getFeatureFlags,
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded,
} from '../../../../common/store/slices/featureSlice';
import SelectLMSType from './CommonFormFields/SelectLMSType';
import ClassSizeInput from './CommonFormFields/ClassSizeInput';
import SelectVILTType from './CommonFormFields/SelectVILTType';

const OPERATIONS_OWNER_DESCRIPTION =
    'The owner of this activity. They will receive notifications of updates to this activity.';

const OPERATIONS_SECONDARY_OWNER_DESCRIPTION =
    'The owner of this activity. They will receive notifications of updates to this activity.';

const SCHEDULER_DESCRIPTION = 'Scheduler or Resource manager of this activity.';
const REQUESTER_DESCRIPTION =
    'Business Development Manager or Partner Enablement Manager requesting this activity.';

const CUSTOMER_SUCCESS_MANAGER_DESCRIPTION =
    'Customer success manager of this activity.';

const CLASS_REQUEST_LINK_DESCRIPTION = 'URL for the class request.';

const SELLER_COUNTRY_DESCRIPTION =
    'This field is used in billing for tax calculations. It is also used as the country for tracking metrics.';

const NUMBER_STUDENTS_DESCRIPTION =
    'The number of students who attended the class.';

const CLASS_SIZE_DESCRIPTION =
    'The total expected class size across all customers.';

const REGISTERED_DESCRIPTION = 'The number of students registered.';

const WAITLISTED_DESCRIPTION = 'The number of students waitlisted.';

const LMS_TYPE_DESCRIPTION = 'The LMS used for the class.';

const LMS_ID_DESCRIPTION = 'The learning object ID from the LMS.';

const LMS_ONE_CLICK_DESCRIPTION = `URL from the LMS for registering students.`;

const APN_REGISTRATION_LINK_DESCRIPTION = `URL from the LMS for APN registration.`;

const COMMERCIAL_REGISTRATION_LINK_DESCRIPTION = `URL from the LMS for commercial registration.`;

const CLASSROOMS_STUDENT_URL_DESCRIPTION = `Student URL for the class created in classrooms.`;

const CLASSROOMS_ARN_DESCRIPTION = `ARN for the class created in classrooms.`;

const COST_CURRENCY_DESCRIPTION = 'The currency used for recording costs.';

const INVITE_ONLY_DESCRIPTION = `Set class invitation status.`;

const getCustomBooleanFieldOptionsAndLookup = ([trueLabel, falseLabel]: [
    string,
    string,
]) => {
    const trueOption = {
        label: trueLabel,
        id: '0',
    };

    const falseOption = {
        label: falseLabel,
        id: '1',
    };

    const valueLookup: {
        [key in 'true' | 'false']: Select.Option;
    } = {
        false: falseOption,
        true: trueOption,
    };

    const boolLookup = {
        [trueLabel]: true,
        [falseLabel]: false,
    };

    return {
        valueOptions: [trueOption, falseOption] as Array<Select.Option>,
        valueLookup,
        boolLookup,
    };
};

interface PaymentStatusOption extends Select.Option {
    readonly label: PaymentStatus;
    readonly id: string;
}

const paymentStatusOptions: Array<PaymentStatusOption> = [
    {
        label: PaymentStatus.PiggyBank,
        id: '0',
    },
    {
        label: PaymentStatus.PendingPayment,
        id: '1',
    },
    {
        label: PaymentStatus.VendorPaid,
        id: '2',
    },
];

const paymentStatusOptionsLookup = paymentStatusOptions.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in PaymentStatus]: PaymentStatusOption;
    },
);

const EditOperationsAndRegistrationDetailsFormSection = ({
    formValues,
    errors,
    controlArrayErrors,
    handleFieldEvent,
    handleCostItemEvent,
    costItemAttributeEditorItems,
}: EditOpsAndRegFormSectionProps<Omit<ActivityData, 'cost_items'>>) => {
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);

    const userSearchText = useSelector(selectUserListSearchText);
    const isUserListLoading = useSelector(selectIsUserListLoading);
    const isUserListLoaded = useSelector(selectIsUserListLoaded);
    const userListError = useSelector(selectUserListError);
    const userProfile = useSelector(selectUser);
    const originalUserList = useSelector(selectUserList);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const countriesList = useSelector(selectAllCountries);
    const isCountriesListLoaded = useSelector(selectIsCountiesLoaded);
    const isCountriesListLoading = useSelector(selectIsCountriesLoading);
    const userList = userProfile?.profile
        ? handlePreSelectedValue(
              userProfile.profile,
              !!formValues.operations_owner && isUserListLoaded,
              originalUserList,
              (user) => user.full_name === formValues.operations_owner,
          )
        : originalUserList;
    const isCostTypeListLoading = useSelector(selectIsCostTypesListLoading);
    const isCostTypeListLoaded = useSelector(selectIsCostTypesListLoaded);
    const costTypesList = useSelector(selectAllActiveCostTypes);

    const isILT = formValues.activity_modality === ActivityModality.ILT;
    const isVILT = formValues.activity_modality === ActivityModality.vILT;
    const isHybrid = formValues.activity_modality === ActivityModality.Hybrid;
    const isViltOrHybrid = isVILT || isHybrid;

    const dispatch = useDispatch();

    // initialize userListSlice query params
    useEffect(() => {
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );

        return () => {
            dispatch(resetUserListSlice());
        };
    }, [dispatch]);

    // fetch form data (userList)
    useEffect(() => {
        (
            [
                [!isUserListLoaded && !isUserListLoading, getUserList],
                [
                    !isCostTypeListLoaded && !isCostTypeListLoading,
                    getCostTypesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    }, [
        dispatch,
        isCostTypeListLoaded,
        isCostTypeListLoading,
        isUserListLoaded,
        isUserListLoading,
    ]);

    useEffect(() => {
        dispatch(getFeatureFlags());
    }, [dispatch]);

    // lifecycle method to fetch (and re-fetch) countries & location data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isCountriesListLoaded && !isCountriesListLoading,
                    getCountriesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const { valueLookup: userLookup, valueOptions: userOptions } =
        getOptionsAndLookupForSelectInput<
            Pick<UserProfileData, 'email' | 'full_name'>
        >(
            [
                ...userList,
                {
                    email: formValues.operations_owner_email,
                    full_name: formValues.operations_owner,
                },
                ...(formValues.additional_owners
                    ? formValues.additional_owners.map((owner) => {
                          return {
                              email: owner.additional_owner_email,
                              full_name: owner.additional_owner_name,
                          };
                      })
                    : []),
            ],
            (
                profile: Partial<UserProfileData> &
                    Pick<UserProfileData, 'email' | 'full_name'>,
            ) => ({
                label: profile.full_name,
                id: profile.email as string,
            }),
        );

    const { valueLookup: costTypeLookup, valueOptions: costTypeOptions } =
        getOptionsAndLookupForSelectInput<CostTypeItemData>(
            costTypesList,
            (costType: CostTypeItemData) => ({
                label: costType.cost_type,
                id: costType.pk as string,
            }),
        );

    const {
        valueLookup: cateringStatusLookup,
        valueOptions: cateringStatusOptions,
        boolLookup: cateringStatusBoolLookup,
    } = getCustomBooleanFieldOptionsAndLookup([
        'Catering is booked',
        'Catering is not booked',
    ]);

    const {
        valueLookup: cateringReqLookup,
        valueOptions: cateringReqOptions,
        boolLookup: cateringReqBoolLookup,
    } = getCustomBooleanFieldOptionsAndLookup([
        'Catering required',
        'Catering not required',
    ]);

    const {
        valueLookup: inviteReqLookup,
        valueOptions: inviteReqOptions,
        boolLookup: inviteReqBoolLookup,
    } = getCustomBooleanFieldOptionsAndLookup([
        'Invitation required',
        'Invitation not required',
    ]);

    const handleOperationsOwnerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                operations_owner: e.detail.selectedOption.label,
                operations_owner_email: e.detail.selectedOption.id,
            });
        }
    };

    const handleSchedulerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                scheduler: e.detail.selectedOption.label,
                scheduler_email: e.detail.selectedOption.id,
            });
        }
    };

    const handleCustomerSuccessManagerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                customer_support_manager: e.detail.selectedOption.id
                    ? e.detail.selectedOption.label
                    : null,
                customer_support_manager_email: e.detail.selectedOption.id,
            });
        }
    };

    const handleAdditionalOperationsOwnerDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
        index: number,
    ) => {
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );
        dispatch(setUserListSearchText(e.detail.value));
        dispatch(getUserList());
    };

    const handleAdditionalOperationsOwnerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
        index: number,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            const newAdditionalOwners = formValues.additional_owners?.map(
                (additionalOwner, ownerIndex) => {
                    if (ownerIndex === index) {
                        return {
                            __id: additionalOwner.__id,
                            additional_owner_name:
                                e.detail.selectedOption.label,
                            additional_owner_email: e.detail.selectedOption.id,
                        };
                    }

                    return additionalOwner;
                },
            );

            handleFieldEvent({
                additional_owners: newAdditionalOwners,
            });
        }
    };

    const handleOperationsOwnerDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        dispatch(setUserListSearchText(e.detail.value));
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );
        dispatch(getUserList());
    };
    const getCustomerSuccessManagerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.customer_support_manager)
            ? userLookup[formValues.customer_support_manager]
            : {
                  id: formValues.customer_support_manager_email,
                  label: formValues.customer_support_manager,
              };
    };

    const getSchedulerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.scheduler)
            ? userLookup[formValues.scheduler]
            : { id: formValues.scheduler_email, label: formValues.scheduler };
    };

    const getOperationsOwnerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.operations_owner)
            ? userLookup[formValues.operations_owner]
            : {
                  id: formValues.operations_owner_email,
                  label: formValues.operations_owner,
              };
    };

    const handleCustomerSuccessManagerDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        dispatch(setUserListSearchText(e.detail.value));
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_READ_ONLY,
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );
        dispatch(getUserList());
    };

    const handleAddSecondaryOwner = () => {
        // add secondary owner
        const d = new Date();
        const existingAdditionalOwners = formValues.additional_owners ?? [];
        handleFieldEvent({
            additional_owners: [
                ...existingAdditionalOwners,
                {
                    __id: `${d.getTime()}`,
                    additional_owner_email: '',
                    additional_owner_name: '',
                },
            ],
        });
    };

    const handleRemoveAdditionalOwner = (index: number) => {
        const existingAdditionalOwners = formValues.additional_owners ?? [];
        const newAdditionalOwners = existingAdditionalOwners.filter(
            (additionalOwner, ownerIndex) => {
                return ownerIndex !== index;
            },
        );
        handleFieldEvent({
            additional_owners: newAdditionalOwners,
        });
    };

    const handleViltFieldEvent = (values: Partial<ActivityData>) => {
        const viltType = values.v_ilt_type;
        if (viltType !== formValues.v_ilt_type) {
            handleFieldEvent({
                v_ilt_type: viltType,
                v_ilt_id: '',
                v_ilt_meeting_id: '',
                lms_one_click_registration_link: '',
                apn_merged_registration_link: '',
                commercial_merged_registration_link: '',
            });
        }
    };

    const costItemAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Cost type',
                control: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) => (
                    <Select
                        placeholder="Select cost type"
                        options={costTypeOptions}
                        selectedOption={
                            costItem.costType
                                ? costTypeLookup[costItem.costType]
                                : null
                        }
                        onChange={(e) => {
                            const newItems =
                                costItemAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...costItem,
                                costType: e.detail.selectedOption.label,
                            });
                            handleCostItemEvent(newItems);
                        }}
                        data-testid={`EditCostType${index}`}
                    />
                ),
                errorText: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.costItemAttributeEditorItems?.[index]
                        ?.costType,
            },
            {
                label: 'Cost amount',
                control: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) => (
                    <Input
                        type="number"
                        value={`${costItem.costAmount}`}
                        onInput={(e) => {
                            const newItems =
                                costItemAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...costItem,
                                costAmount: parseFloat(e.detail.value),
                            });
                            handleCostItemEvent(newItems);
                        }}
                        data-testid={`EditCostAmount${index}`}
                    />
                ),
                errorText: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.costItemAttributeEditorItems?.[index]
                        ?.costAmount,
            },
            {
                label: 'Payment PO# or Pcard',
                control: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) => (
                    <Input
                        value={costItem.paymentPo}
                        onInput={(e) => {
                            const newItems =
                                costItemAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...costItem,
                                paymentPo: e.detail.value,
                            });
                            handleCostItemEvent(newItems);
                        }}
                        data-testid={`EditPaymentPo${index}`}
                    />
                ),
                errorText: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.costItemAttributeEditorItems?.[index]
                        ?.paymentPo,
            },
            {
                label: 'Payment status',
                control: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) => (
                    <Select
                        placeholder="Select payment status"
                        options={paymentStatusOptions}
                        selectedOption={
                            costItem.paymentStatus
                                ? paymentStatusOptionsLookup[
                                      costItem.paymentStatus as PaymentStatus
                                  ]
                                : null
                        }
                        onChange={(e) => {
                            const newItems =
                                costItemAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...costItem,
                                paymentStatus: e.detail.selectedOption.label,
                            });
                            handleCostItemEvent(newItems);
                        }}
                        data-testid={`EditPaymentStatus${index}`}
                    />
                ),
                errorText: (
                    costItem: CostItemAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.costItemAttributeEditorItems?.[index]
                        ?.paymentStatus,
            },
        ];

    const additionalOwnerList = formValues.additional_owners?.map(
        (additionalOwner, index) => {
            const key = additionalOwner.additional_owner_name
                .replace(' ', '')
                .toLowerCase();
            return (
                <FormField
                    label="Secondary owner"
                    description={OPERATIONS_SECONDARY_OWNER_DESCRIPTION}
                    errorText={errors?.operations_owner}
                    key={`additional-owners-${index}`}
                    secondaryControl={
                        <Button
                            data-testid={`remove-additional-owner-${index}`}
                            onClick={(e) => handleRemoveAdditionalOwner(index)}
                        >
                            Remove
                        </Button>
                    }
                >
                    <Select
                        className={
                            errors?.operations_owner && FORM_ERROR_SELECTOR
                        }
                        placeholder={
                            isUserListLoading ? 'Loading users' : 'Select user'
                        }
                        empty="No users found"
                        loadingText="Loading users"
                        filteringType="manual"
                        errorText="An error occurred while loading users"
                        recoveryText="Retry"
                        statusType={getStatusType(
                            isUserListLoading,
                            isUserListLoaded,
                            userListError,
                        )}
                        options={userOptions}
                        selectedOption={
                            userLookup[additionalOwner.additional_owner_name]
                        }
                        filteringPlaceholder="Enter a user name"
                        onDelayedFilteringChange={(e) =>
                            handleAdditionalOperationsOwnerDelayedFilteringChange(
                                e,
                                index,
                            )
                        }
                        onChange={(e) =>
                            handleAdditionalOperationsOwnerChange(e, index)
                        }
                        onRecoveryClick={() => {
                            dispatch(setUserListSearchText(''));
                            dispatch(resetUserListSlice());
                            dispatch(getUserList());
                        }}
                        data-testid={`EditAdditionalActivityOperationsOwner-${key}`}
                        disabled={!isUserListLoaded}
                    />
                </FormField>
            );
        },
    );

    const countryOptions = countriesList.map((country: any) => {
          return {
              id: country.country,
              label: country.country,
          };
    });

    return (
        <FormSection
            data-testid="EditOperationsAndRegistrationDetailsFormSection"
            header="Edit details"
        >
            <ColumnLayout data-testid="InstructorDetailsFormFields">
                <div data-awsui-column-layout-root="true">
                    <div className="grimsby-sub-section-header">
                        Operations details
                    </div>
                    <FormField
                        label="Operations owner"
                        description={OPERATIONS_OWNER_DESCRIPTION}
                        errorText={errors?.operations_owner}
                    >
                        <Select
                            className={
                                errors?.operations_owner && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={userOptions}
                            selectedOption={getOperationsOwnerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleOperationsOwnerDelayedFilteringChange
                            }
                            onChange={handleOperationsOwnerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={`EditActivityOperationsOwner`}
                            disabled={!isUserListLoaded}
                        />
                    </FormField>
                    {additionalOwnerList}
                    <FormField>
                        <Button
                            data-testid="add-secondary-owner"
                            onClick={handleAddSecondaryOwner}
                        >
                            Add secondary owner
                        </Button>
                    </FormField>
                    <FormField
                        label="Scheduler"
                        description={SCHEDULER_DESCRIPTION}
                        errorText={errors?.scheduler}
                    >
                        <Select
                            className={errors?.scheduler && FORM_ERROR_SELECTOR}
                            placeholder={
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={userOptions}
                            selectedOption={getSchedulerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleOperationsOwnerDelayedFilteringChange
                            }
                            onChange={handleSchedulerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={`EditActivityScheduler`}
                            disabled={!isUserListLoaded}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Customer success manager <i>- optional</i>
                            </span>
                        }
                        description={CUSTOMER_SUCCESS_MANAGER_DESCRIPTION}
                        errorText={errors?.customer_support_manager}
                    >
                        <Select
                            className={
                                errors?.customer_support_manager &&
                                FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={[{ id: null, label: '-' }, ...userOptions]}
                            selectedOption={getCustomerSuccessManagerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleCustomerSuccessManagerDelayedFilteringChange
                            }
                            onChange={handleCustomerSuccessManagerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={`EditActivityCustomerSuccessManager`}
                            disabled={!isUserListLoaded}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Requester <i>- optional</i>
                            </span>
                        }
                        description={REQUESTER_DESCRIPTION}
                        errorText={errors?.requestor}
                    >
                        <Input
                            className={errors?.requestor && FORM_ERROR_SELECTOR}
                            value={formValues.requestor}
                            onInput={(e) =>
                                handleFieldEvent({
                                    requestor: e.detail.value,
                                })
                            }
                            data-testid={`EditRequester`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Class request SIM <i>- optional</i>
                            </span>
                        }
                        description={CLASS_REQUEST_LINK_DESCRIPTION}
                        errorText={errors?.class_request_sim}
                    >
                        <Input
                            className={
                                errors?.class_request_sim && FORM_ERROR_SELECTOR
                            }
                            value={formValues.class_request_sim}
                            onInput={(e) =>
                                handleFieldEvent({
                                    class_request_sim: e.detail.value,
                                })
                            }
                            data-testid={`EditClassRequestSIM`}
                        />
                    </FormField>
                    {!isILT && (
                        <>
                            <FormField
                                label={
                                    <span>
                                        Seller of record (SOR) country{' '}
                                        <i>- optional</i>
                                    </span>
                                }
                                description={SELLER_COUNTRY_DESCRIPTION}
                            >
                                <Select
                                    className={
                                        errors?.sor_country &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    selectedOption={{
                                        id:
                                            formValues.sor_country ||
                                            formValues.delivery_country,
                                        label:
                                            formValues.sor_country ||
                                            formValues.delivery_country,
                                    }}
                                    placeholder="Country"
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            sor_country:
                                                e.detail.selectedOption.label,
                                        })
                                    }
                                    filteringType="auto"
                                    data-testid={`EditSorCountry`}
                                    options={countryOptions}
                                />
                            </FormField>
                        </>
                    )}
                    <FormField
                        label={
                            <span>
                                Catering requirement <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.catering_required}
                    >
                        <Select
                            className={
                                errors?.catering_required && FORM_ERROR_SELECTOR
                            }
                            placeholder="Select catering requirement"
                            options={cateringReqOptions}
                            selectedOption={
                                formValues.catering_required !== undefined &&
                                formValues.catering_required !== null
                                    ? cateringReqLookup[
                                          formValues.catering_required.toString() as
                                              | 'true'
                                              | 'false'
                                      ]
                                    : null
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    catering_required:
                                        cateringReqBoolLookup[
                                            e.detail.selectedOption.label
                                        ],
                                })
                            }
                            data-testid={`EditCateringRequired`}
                        />
                    </FormField>
                    {formValues.catering_required && (
                        <>
                            <FormField
                                label={
                                    <span>
                                        Caterer name <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.caterer_name}
                            >
                                <Input
                                    className={
                                        errors?.caterer_name &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.caterer_name}
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            caterer_name: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditCatererName`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        Number of students for catering{' '}
                                        <i>- optional</i>
                                    </span>
                                }
                                errorText={
                                    errors?.number_of_students_for_catering
                                }
                            >
                                <Input
                                    className={
                                        errors?.number_of_students_for_catering &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={`${formValues.number_of_students_for_catering}`}
                                    type="number"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            number_of_students_for_catering: !!e
                                                .detail.value
                                                ? parseFloat(e.detail.value)
                                                : null,
                                        })
                                    }
                                    data-testid={`EditNumberOfStudentsForCatering`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        Catering status <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.is_catering_booked}
                            >
                                <Select
                                    className={
                                        errors?.is_catering_booked &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    placeholder="Select catering status"
                                    options={cateringStatusOptions}
                                    selectedOption={
                                        formValues.is_catering_booked !==
                                            undefined &&
                                        formValues.is_catering_booked !== null
                                            ? cateringStatusLookup[
                                                  formValues.is_catering_booked.toString() as
                                                      | 'true'
                                                      | 'false'
                                              ]
                                            : null
                                    }
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            is_catering_booked:
                                                cateringStatusBoolLookup[
                                                    e.detail.selectedOption
                                                        .label
                                                ],
                                        })
                                    }
                                    data-testid={`EditCateringRequired`}
                                />
                            </FormField>
                        </>
                    )}
                    <FormField
                        label={
                            <span>
                                Number of students attended <i>- optional</i>
                            </span>
                        }
                        description={NUMBER_STUDENTS_DESCRIPTION}
                        errorText={errors?.attended}
                    >
                        <Input
                            className={errors?.attended && FORM_ERROR_SELECTOR}
                            value={`${formValues.attended}`}
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    attended: !!e.detail.value
                                        ? parseFloat(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditAttended`}
                        />
                    </FormField>
                    {formValues.activity_audience === AudienceType.Public && (
                        <>
                            <FormField
                                label={
                                    <span>
                                        Billing currency <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.billed_currency}
                            >
                                <Select
                                    options={currencyCodesOptions}
                                    placeholder="Select billing currency"
                                    selectedOption={
                                        formValues.billed_currency
                                            ? currencyCodesOptionsLookup[
                                                  formValues.billed_currency
                                              ]
                                            : null
                                    }
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            billed_currency:
                                                e.detail.selectedOption.label,
                                        })
                                    }
                                    data-testid={`EditBillingCurrency`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        Total billed revenue <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.total_billed_revenue}
                            >
                                <Input
                                    className={
                                        errors?.total_billed_revenue &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={`${formValues.total_billed_revenue}`}
                                    type="number"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            total_billed_revenue: !!e.detail
                                                .value
                                                ? parseFloat(e.detail.value)
                                                : null,
                                        })
                                    }
                                    data-testid={`EditTotalBilledRevenue`}
                                />
                            </FormField>
                        </>
                    )}
                    <div className="grimsby-sub-section-divider" />
                    <div className="grimsby-sub-section-header">
                        Registration details
                    </div>
                    <ClassSizeInput
                        {...{
                            setClassSize: handleFieldEvent,
                            errors,
                            classSize: formValues.class_size,
                        }}
                    />
                    <FormField
                        label={
                            <span>
                                Registered <i>- optional</i>
                            </span>
                        }
                        description={REGISTERED_DESCRIPTION}
                        errorText={errors?.registered}
                    >
                        <Input
                            className={
                                errors?.registered && FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.registered}`}
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    registered: !!e.detail.value
                                        ? parseFloat(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditRegistered`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Waitlisted <i>- optional</i>
                            </span>
                        }
                        description={WAITLISTED_DESCRIPTION}
                        errorText={errors?.waitlisted}
                    >
                        <Input
                            className={
                                errors?.waitlisted && FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.waitlisted}`}
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    waitlisted: !!e.detail.value
                                        ? parseFloat(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditWaitlisted`}
                        />
                    </FormField>
                    <SelectLMSType
                        {...{
                            handleFieldEvent,
                            formValues: {
                                activity_modality: formValues.activity_modality,
                                lms_type: formValues.lms_type,
                                v_ilt_type: formValues?.v_ilt_type,
                            },
                            errors: {
                                activity_modality: errors.activity_modality,
                                lms_type: errors.lms_type,
                                v_ilt_type: errors.v_ilt_type,
                            },
                        }}
                    />
                    <FormField
                        label={
                            <span>
                                LMS ID <i>- optional</i>
                            </span>
                        }
                        description={LMS_ID_DESCRIPTION}
                        errorText={errors?.lms_id}
                    >
                        <Input
                            className={errors?.lms_id && FORM_ERROR_SELECTOR}
                            value={formValues.lms_id}
                            onInput={(e) =>
                                handleFieldEvent({
                                    lms_id: e.detail.value,
                                })
                            }
                            data-testid={`EditLMSID`}
                        />
                    </FormField>
                    {formValues.lms_type === ActivityLMSType.MyClass && (
                        <FormField
                        label={
                            <span>
                                Invite Only
                            </span>
                        }
                        errorText={errors?.invite_only}
                    >
                        <Select
                            className={
                                errors?.invite_only && FORM_ERROR_SELECTOR
                            }
                            placeholder="Set invitation requirment for class"
                            options={inviteReqOptions}
                            selectedOption={
                                formValues.invite_only !== undefined &&
                                formValues.invite_only !== null
                                    ? inviteReqLookup[
                                          formValues.invite_only.toString() as
                                              | 'true'
                                              | 'false'
                                      ]
                                    : null
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    invite_only:
                                        inviteReqBoolLookup[
                                            e.detail.selectedOption.label
                                        ],
                                })
                            }
                            data-testid={`EditInvitationRequired`}
                        />
                    </FormField>
                    )}

                    {formValues.lms_type === ActivityLMSType.Kiku && (
                        <>
                            <FormField
                                label={
                                    <span>
                                        LMS one-click registration link{' '}
                                        <i>- optional</i>
                                    </span>
                                }
                                description={LMS_ONE_CLICK_DESCRIPTION}
                                errorText={
                                    errors?.lms_one_click_registration_link
                                }
                            >
                                <Input
                                    className={
                                        errors?.lms_one_click_registration_link &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={
                                        formValues.lms_one_click_registration_link
                                    }
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            lms_one_click_registration_link:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditLMSOneClickLink`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        APN Merged Registration Link{' '}
                                        <i>- optional</i>
                                    </span>
                                }
                                description={APN_REGISTRATION_LINK_DESCRIPTION}
                                errorText={errors?.apn_merged_registration_link}
                            >
                                <Input
                                    className={
                                        errors?.apn_merged_registration_link &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={
                                        formValues.apn_merged_registration_link
                                    }
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            apn_merged_registration_link:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditAPNRegistrationLink`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        Commercial Merged Registration Link{' '}
                                        <i>- optional</i>
                                    </span>
                                }
                                description={
                                    COMMERCIAL_REGISTRATION_LINK_DESCRIPTION
                                }
                                errorText={
                                    errors?.commercial_merged_registration_link
                                }
                            >
                                <Input
                                    className={
                                        errors?.commercial_merged_registration_link &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={
                                        formValues.commercial_merged_registration_link
                                    }
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            commercial_merged_registration_link:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditCommercialRegistrationLink`}
                                />
                            </FormField>
                        </>
                    )}
                    {isViltOrHybrid && (
                        <>
                            <SelectVILTType
                                {...{
                                    handleFieldEvent: handleViltFieldEvent,
                                    formValues: {
                                        lms_type: formValues.lms_type,
                                        v_ilt_type: formValues?.v_ilt_type,
                                    },
                                    errors: {
                                        lms_type: errors.lms_type,
                                        v_ilt_type: errors.v_ilt_type,
                                    },
                                }}
                            />
                            <FormField
                                label={
                                    <span>
                                        vILT link <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.v_ilt_id}
                            >
                                <Input
                                    className={
                                        errors?.v_ilt_id && FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.v_ilt_id}
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            v_ilt_id: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditvILTSystemID`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        vILT Meeting ID <i>- optional</i>
                                    </span>
                                }
                                errorText={errors?.v_ilt_meeting_id}
                            >
                                <Input
                                    className={
                                        errors?.v_ilt_meeting_id &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.v_ilt_meeting_id}
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            v_ilt_meeting_id: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditvILTMeetingID`}
                                />
                            </FormField>
                        </>
                    )}
                    <FormField
                        label={
                            <span>
                                Builder Labs Classrooms Student URL{' '}
                                <i>- optional</i>
                            </span>
                        }
                        description={CLASSROOMS_STUDENT_URL_DESCRIPTION}
                        errorText={errors?.classrooms_student_url}
                    >
                        <Input
                            className={
                                errors?.class_request_sim && FORM_ERROR_SELECTOR
                            }
                            value={formValues.classrooms_student_url}
                            onInput={(e) =>
                                handleFieldEvent({
                                    classrooms_student_url: e.detail.value,
                                })
                            }
                            data-testid={`EditClassroomsStudentURL`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Builder Labs Classrooms Arn <i>- optional</i>
                            </span>
                        }
                        description={CLASSROOMS_ARN_DESCRIPTION}
                        errorText={errors?.classrooms_arn}
                    >
                        <Input
                            className={
                                errors?.class_request_sim && FORM_ERROR_SELECTOR
                            }
                            value={formValues.classrooms_arn}
                            onInput={(e) =>
                                handleFieldEvent({
                                    classrooms_arn: e.detail.value,
                                })
                            }
                            data-testid={`EditClassroomsARN`}
                        />
                    </FormField>
                    <div className="grimsby-sub-section-divider" />
                    <div className="grimsby-sub-section-header">Cost items</div>
                    <FormField
                        label="Cost currency"
                        description={COST_CURRENCY_DESCRIPTION}
                        errorText={
                            !!errors?.cost_currency
                                ? 'Cost currency must be selected to save cost items'
                                : null
                        }
                    >
                        <Select
                            options={currencyCodesOptions}
                            placeholder="Select cost currency"
                            selectedOption={
                                formValues.cost_currency
                                    ? currencyCodesOptionsLookup[
                                          formValues.cost_currency
                                      ]
                                    : null
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    cost_currency:
                                        e.detail.selectedOption.label,
                                })
                            }
                            data-testid={`EditCostCurrency`}
                        />
                    </FormField>
                    <FormField>
                        <AttributeEditor
                            data-testid={`EditSessionAttributeEditor`}
                            addButtonText="Add cost item"
                            removeButtonText="Remove"
                            items={costItemAttributeEditorItems}
                            definition={costItemAttributeEditorDefinition}
                            onAddButtonClick={() =>
                                handleCostItemEvent([
                                    ...costItemAttributeEditorItems,
                                    {
                                        costAmount: null,
                                        costType: '',
                                        paymentStatus: '',
                                        paymentPo: '',
                                    },
                                ])
                            }
                            onRemoveButtonClick={({
                                detail: { itemIndex },
                            }) => {
                                const newList =
                                    costItemAttributeEditorItems.slice();
                                newList.splice(itemIndex, 1);
                                handleCostItemEvent(newList);
                            }}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

EditOperationsAndRegistrationDetailsFormSection.propTypes = {
    costItemAttributeEditorItems: PropTypes.array.isRequired,
};

export default EditOperationsAndRegistrationDetailsFormSection;

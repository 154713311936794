import { CatalogCoursesResponseData } from "../../interfaces/businessDataResponse/catalogCoursesResponse";
import { HandleRequestData } from '../../interfaces/handleRequest';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const GET_CATALOG_COURSES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/get-catalog-courses`;

/**
 * Method to fetch Catalog courses by given group id.
 * @param {string} groupId - catalog group id for the course group
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCatalogCourses = (
    groupId: string,
): Promise<HandleRequestData<CatalogCoursesResponseData>> => {
    return handleGrimsbyApiRequest<CatalogCoursesResponseData>(
        `${GET_CATALOG_COURSES_PATH}/${groupId}`,
        {
            method: 'GET',
        },
    );
};

const catalogCourseApi = {
    getCatalogCourses,
};

export default catalogCourseApi;